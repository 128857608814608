<template>
  <div id="user-container">
    <v-row id="user-btn-group" no-gutters>
      <v-col cols="1" lg="0" md="0" sm="0" class="left-spacer" />
      <v-col cols="10" lg="12" xl="10" md="12" sm="12" class="main-content">
        <v-btn-toggle v-model="toggle">
          <router-link to="/users/groups" v-if="accessibleRoutes.userGroups">
            <v-btn :ripple="false" :disabled="toggle == 0"> User Group Management </v-btn>
          </router-link>
          <router-link to="/users/list" v-if="accessibleRoutes.users">
            <v-btn :ripple="false" :disabled="toggle == 1">
              User Management</v-btn
            ></router-link
          >
          <router-link to="/users/profile">
            <v-btn :ripple="false" :disabled="toggle == 2"> User Profile </v-btn>
          </router-link>
        </v-btn-toggle>
      </v-col>
      <v-col cols="1" lg="0" md="0" sm="0" />
    </v-row>
    <router-view></router-view>
  </div>
</template>

<style lang="scss" src="../../assets/css/users.scss"></style>

<script>
export default {
  data() {
    return {
      toggle: 0,
    };
  },
  created() {
    this.toggle = 0;
    if (this.$router.currentRoute.name == "User Management") {
      this.toggle = 1;
    }
    if (this.$router.currentRoute.name == "User Profile") {
      this.toggle = 2;
    }
  },
  computed: {
    accessibleRoutes: function () {
      return this.$store.getters.accessibleRoutes;
    },
  },
};
</script>
